import styled from 'styled-components';

import { colors } from '@/styles/GlobalStyle';

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 151.56px;
  height: 40px;

  padding: 8px 24px 9px 24px;
  margin-top: 8px;

  background-color: ${colors.blue400};
  color: ${colors.white};

  border: none;
  border-radius: 8px;

  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;

  transition: background-color 0.2s;

  &:hover {
    background-color: ${colors.blue500};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${colors.gray200};
    color: ${colors.gray400};
  }

  svg {
    width: 15.56px;
    height: 15.56px;
  }
`;
