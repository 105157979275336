import React from 'react';

import { Button } from './styles';

interface ISubmitButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  disableSubmitButton?: boolean;
}

import SearchIcon from '@/images/icons/icon-search.svg';
import SearchIconDisabled from '@/images/icons/icon-search-disabled.svg';

const SubmitButton: React.FC<ISubmitButtonProps> = ({
  disableSubmitButton,
  ...rest
}) => {
  return (
    <Button
      disabled={disableSubmitButton}
      title={disableSubmitButton ? 'Preencha todos os campos' : ''}
      type="submit"
      {...rest}
    >
      Rastrear
      <img
        src={disableSubmitButton ? SearchIconDisabled : SearchIcon}
        alt="Ícone de lupa de busca."
      />
    </Button>
  );
};

export default SubmitButton;
