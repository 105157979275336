import React, { useState } from 'react';

import IconNewPageWhite from '@/images/icons/icon-redirect-new-page-white.svg';

import {
  SectionWrapper,
  RastreamentoTitleContainer,
  Button,
  Form,
  TextAreaContainer,
  InputContainer,
} from './styles';

import { removeAllNonNumberCharacters } from '@/utils/removeAllNonNumberCharacters';

const FormCNPMMultipleNF: React.FC = () => {
  const [cnpj, setCnpj] = useState({ value: '', active: false });
  const [senha, setSenha] = useState({ value: '', active: false });
  const [nf, setNf] = useState({ value: '', active: false });

  const handleChangeCnpj = (e: any) => {
    setCnpj({ ...cnpj, value: removeAllNonNumberCharacters(e.target?.value) });
  };

  const handleChangeSenha = (e: any) => {
    setSenha({
      ...senha,
      value: e.target?.value,
    });
  };

  const handleChangeNf = (e: any) => {
    setNf({ ...nf, value: e.target?.value });
  };

  return (
    <>
      <RastreamentoTitleContainer>
        <h2>Consulta CNPJ várias NFe&apos;s:</h2>
      </RastreamentoTitleContainer>

      <SectionWrapper>
        <Form action="https://ssw.inf.br/2/ssw_resultSSW_pag_nro" method="POST">
          <InputContainer active={!!cnpj.value || cnpj.active}>
            <input
              name="cnpjpag"
              placeholder="Informe o CNPJ"
              type="tel"
              value={cnpj.value}
              onChange={handleChangeCnpj}
              onFocus={() => setCnpj({ ...cnpj, active: true })}
              onBlur={() => setCnpj({ ...cnpj, active: false })}
            />
            <label>CNPJ</label>
          </InputContainer>

          <TextAreaContainer active={!!nf.value || nf.active}>
            <textarea
              name="NR"
              placeholder="Informe apenas uma nota fiscal por linha"
              onChange={handleChangeNf}
              value={nf.value}
              onFocus={() => setNf({ ...nf, active: true })}
              onBlur={() => setNf({ ...nf, active: false })}
            />
            <label>NFe&apos;s</label>
          </TextAreaContainer>

          <InputContainer active={!!senha.value || senha.active}>
            <input
              name="chave"
              placeholder="Senha (Opcional)"
              type="password"
              value={senha.value}
              onChange={handleChangeSenha}
              onFocus={() => setSenha({ ...senha, active: true })}
              onBlur={() => setSenha({ ...senha, active: false })}
            />
            <label>Senha</label>
          </InputContainer>

          <Button type="submit">
            Rastrear <img src={IconNewPageWhite} alt="Seta diagonal" />
          </Button>

          <input
            type="hidden"
            name="urlori"
            value="https://generoso.com.br/rastreamento"
          />
        </Form>
      </SectionWrapper>
    </>
  );
};

export default FormCNPMMultipleNF;
