import React, { useState } from 'react';

import IconNewPageWhite from '@/images/icons/icon-redirect-new-page-white.svg';

import {
  SectionWrapper,
  RastreamentoTitleContainer,
  Button,
  Form,
  InputContainer,
} from './styles';

import { removeAllNonNumberCharacters } from '@/utils/removeAllNonNumberCharacters';

const FormCNPMMultipleNF: React.FC = () => {
  const [cnpj, setCnpj] = useState({ value: '', active: false });
  const [senha, setSenha] = useState({ value: '', active: false });

  const handleChangeCnpj = (e: any) => {
    setCnpj({ ...cnpj, value: removeAllNonNumberCharacters(e.target?.value) });
  };

  const handleChangeSenha = (e: any) => {
    setSenha({
      ...senha,
      value: e.target?.value,
    });
  };

  return (
    <>
      <RastreamentoTitleContainer>
        <h2>Consulta por CNPJ pagador com senha:</h2>
      </RastreamentoTitleContainer>

      <SectionWrapper>
        <Form action="https://ssw.inf.br/2/ssw_resultSSW_pag" method="POST">
          <InputContainer active={!!cnpj.value || cnpj.active}>
            <input
              name="cnpj"
              placeholder="Informe o CNPJ"
              type="tel"
              value={cnpj.value}
              onChange={handleChangeCnpj}
              onFocus={() => setCnpj({ ...cnpj, active: true })}
              onBlur={() => setCnpj({ ...cnpj, active: false })}
            />
            <label>CNPJ</label>
          </InputContainer>

          <InputContainer active={!!senha.value || senha.active}>
            <input
              name="senha"
              placeholder="Senha (Opcional)"
              type="password"
              value={senha.value}
              onChange={handleChangeSenha}
              onFocus={() => setSenha({ ...senha, active: true })}
              onBlur={() => setSenha({ ...senha, active: false })}
            />
            <label>Senha</label>
          </InputContainer>

          <Button type="submit">
            Rastrear <img src={IconNewPageWhite} alt="Seta diagonal" />
          </Button>

          <input
            type="hidden"
            name="urlori"
            value="https://generoso.com.br/rastreamento"
          />
        </Form>
      </SectionWrapper>
    </>
  );
};

export default FormCNPMMultipleNF;
