import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { navigate, graphql, useStaticQuery } from 'gatsby';
import { FormHandles } from '@unform/core';
import * as yup from 'yup';

import SEO from '@/components/SEO';
import Layout from '@/components/Layout';
import BreadCrumbs from '@/components/BreadCrumbs';
import DefaultInput from '@/components/Inputs/DefaultInput';
import SubmitButton from '@/components/Pages/Rastreamento/SubmitButton';
import FormWithPassword from '@/components/Pages/Rastreamento/FormWithPassword';
import FormCNPJWithMultipleNF from '@/components/Pages/Rastreamento/FormCNPJWithMultipleNF';

import { removeAllNonNumberCharacters } from '@/utils/removeAllNonNumberCharacters';
import { getValidationErrors } from '@/utils/getValidationErrors';
import { validateCPForCNPJ } from '@/utils/cpfOrCnpjValidator/cnpjAndCpf';

import {
  RastreamentoWrapper,
  RastreamentoSection,
  SectionWrapper,
  RastreamentoBreadCrumbsContainer,
  RastreamentoTitleContainer,
  Form,
  TipoPagadorContainer,
} from '@/styles/pages/rastreamento';
import { CMS_URL } from '@/config';

const pageBreadCrumbs = [
  { name: 'Página inicial', relativeURL: '/' },
  { name: 'Rastreamento' },
];

interface IFormData {
  cpfOrCnpj: string;
  notaFiscal: number;
}

const Rastreamento = (): JSX.Element => {
  const formRef = useRef<FormHandles>(null);

  const [tipo, setTipo] = useState('');
  const [cpfOrCnpj, setCpfOrCnpj] = useState('');

  const [tipoErrorMessage, setTipoErrorMessage] = useState('');

  const isCpfOrCnpj = useMemo(() => {
    const parsedCpfOrCnpj = removeAllNonNumberCharacters(cpfOrCnpj);

    if (parsedCpfOrCnpj.length === 11) {
      return 'cpf';
    }

    if (parsedCpfOrCnpj.length > 11) {
      return 'cnpj';
    }

    return '';
  }, [cpfOrCnpj]);

  useEffect(() => {
    if (isCpfOrCnpj === 'cpf') {
      setTipo('destinatario');
    } else {
      setTipo('');
    }
  }, [isCpfOrCnpj]);

  useEffect(() => {
    setTipoErrorMessage('');
  }, [tipo]);

  const handleFormSubmit = useCallback(
    async (formData: IFormData) => {
      const { cpfOrCnpj, notaFiscal } = formData;

      const schema = yup.object().shape({
        isCpfOrCnpj: yup.string(),
        cpfOrCnpj: yup
          .string()
          .required('*CPF/CNPJ obrigatório!')
          .test(
            'test-cpfOrCnpj-validation',
            '*CPF/CNPJ inválido!',
            (cpfOrCnpj) => validateCPForCNPJ(cpfOrCnpj)
          ),
        tipo: yup.string().required('*Insira o tipo de pagador!'),
        notaFiscal: yup
          .string()
          .test(
            'test-notaFiscal-validation',
            '*Insira apenas números!',
            (notaFiscal) => {
              if (notaFiscal) {
                return !!Number(notaFiscal);
              }

              return true;
            }
          )
          .test(
            'required-if-isCpfOrCnpj-cnpj',
            '*Insira o número da nota fiscal!',
            (notaFiscal) => {
              if (isCpfOrCnpj === 'cnpj') {
                return !!notaFiscal;
              }

              return true;
            }
          ),
      });

      try {
        formRef.current?.setErrors({});
        setTipoErrorMessage('');

        await schema.validate(
          { isCpfOrCnpj, cpfOrCnpj, notaFiscal, tipo },
          {
            abortEarly: false,
          }
        );
      } catch (yupErr: any) {
        const errors = getValidationErrors(yupErr);

        if (errors.tipo) {
          setTipoErrorMessage(errors.tipo);
        }

        return formRef.current?.setErrors(errors);
      }

      const parsedCpfOrCnpj = removeAllNonNumberCharacters(cpfOrCnpj);

      if (isCpfOrCnpj === 'cpf' && !notaFiscal) {
        return navigate(
          `/rastreamento/encomendas?${isCpfOrCnpj}=${parsedCpfOrCnpj}`
        );
      }

      navigate(
        `/rastreamento/resultado?${isCpfOrCnpj}=${parsedCpfOrCnpj}&notaFiscal=${notaFiscal}&tipo=${tipo}`
      );
    },
    [tipo, isCpfOrCnpj]
  );

  const query = graphql`
    query {
      directus {
        site_pages(filter: { slug: { _contains: "rastreamento" } }) {
          seo {
            title
            description
            keywords
            image {
              id
            }
          }
        }
      }
    }
  `;

  const { directus } = useStaticQuery(query);

  const SEOData = directus.site_pages[0].seo;

  return (
    <>
      <SEO
        pageTitle="Rastreamento"
        title={SEOData.title}
        description={SEOData.description}
        image={`${CMS_URL}/assets/${SEOData.image}`}
        keywords={SEOData.keywords}
      />
      <Layout>
        <RastreamentoWrapper>
          <RastreamentoSection>
            <RastreamentoBreadCrumbsContainer>
              <BreadCrumbs pageBreadCrumbs={pageBreadCrumbs} />
            </RastreamentoBreadCrumbsContainer>

            <RastreamentoTitleContainer>
              <h2>Rastreie sua mercadoria:</h2>
            </RastreamentoTitleContainer>

            <SectionWrapper>
              <Form
                ref={formRef}
                onSubmit={handleFormSubmit}
                action="/rastreamento/resultado"
              >
                <DefaultInput
                  tabIndex={1}
                  name="cpfOrCnpj"
                  labelName="CPF / CNPJ"
                  placeholder="Informe o CPF ou o CNPJ"
                  mask={['999.999.999-99', '99.999.999/9999-99']}
                  setInputValue={setCpfOrCnpj}
                  type="tel"
                />

                {isCpfOrCnpj === 'cnpj' && (
                  <>
                    <TipoPagadorContainer error={!!tipoErrorMessage}>
                      <select
                        value={tipo}
                        onChange={(e) => {
                          setTipo(e.target.value);
                        }}
                      >
                        <option hidden value="">
                          Tipo
                        </option>
                        <option value="remetente">Remetente</option>
                        <option value="destinatario">Destinatario</option>
                      </select>

                      <span>{tipoErrorMessage}</span>
                    </TipoPagadorContainer>

                    <DefaultInput
                      name="notaFiscal"
                      labelName="Nota fiscal"
                      placeholder="Nota fiscal"
                      autoComplete="off"
                      type="tel"
                    />
                  </>
                )}

                <SubmitButton />
              </Form>
            </SectionWrapper>

            <FormWithPassword />

            <FormCNPJWithMultipleNF />
          </RastreamentoSection>
        </RastreamentoWrapper>
      </Layout>
    </>
  );
};

export default Rastreamento;
