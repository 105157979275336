import styled from 'styled-components';
import { Form as Unform } from '@unform/web';

import { colors } from '../GlobalStyle';

interface ITipoPagadorContainerProps {
  error: boolean;
}

export const RastreamentoWrapper = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  max-width: 100vw;

  height: 100%;
  min-height: 500px;
`;

export const RastreamentoSection = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0px 30px 253px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1114px) {
    justify-content: center;
  }

  @media (max-width: 499px) {
    margin: 0px 16px 253px;
  }
`;

export const SectionWrapper = styled.section`
  display: flex;

  justify-content: flex-start;

  width: 100%;
  max-width: 1380px;
`;

export const RastreamentoBreadCrumbsContainer = styled(SectionWrapper)`
  margin: 40px 0px;

  @media (max-width: 650px) {
    display: none;
  }
`;

export const RastreamentoTitleContainer = styled(SectionWrapper)`
  h2 {
    font-weight: bold;
  }

  @media (max-width: 650px) {
    margin-top: 40px;
  }
`;

export const Form = styled(Unform)`
  width: 100%;
  max-width: 304.56px; // tamanho do título

  margin-top: 5px;

  div {
    input {
      height: 48px;
    }
  }
`;

export const TipoPagadorContainer = styled.div<ITipoPagadorContainerProps>`
  max-height: 66px;

  margin-top: 8px;

  select {
    border-radius: 8px;
    border: 1px solid ${(props) => (props.error ? colors.red : colors.gray300)};
    outline: 0;

    width: 100%;

    height: 48px;
    padding-left: 20px;

    font-size: 14px;

    background-color: transparent;
    color: ${colors.gray600};

    -webkit-appearance: none;
    -moz-appearance: none;

    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: 11px;

    &:active {
      border: 1px solid ${colors.blue400};
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  select + span {
    margin-left: 5px;
    font-size: 12px;
    height: 10px;

    color: ${colors.red};
  }
`;
