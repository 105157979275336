import styled, { css } from 'styled-components';

import { colors } from '@/styles/GlobalStyle';

interface ContainerProps {
  active: boolean;
}

export const SectionWrapper = styled.section`
  display: flex;

  justify-content: flex-start;

  width: 100%;
  max-width: 1380px;
`;

export const RastreamentoTitleContainer = styled(SectionWrapper)`
  margin-top: 40px;

  h2 {
    max-width: 300px;
    font-weight: bold;
  }

  @media (max-width: 650px) {
    margin-top: 40px;
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 304.56px;

  margin-top: 5px;

  div {
    input {
      height: 48px;
    }
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 151.56px;
  height: 40px;

  padding: 8px 24px 9px 24px;
  margin-top: 8px;

  background-color: ${colors.blue400};
  color: ${colors.white};

  border: none;
  border-radius: 8px;

  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;

  transition: background-color 0.2s;

  &:hover {
    background-color: ${colors.blue500};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${colors.gray200};
    color: ${colors.gray400};
  }

  svg {
    width: 15.56px;
    height: 15.56px;
  }
`;

export const TextAreaContainer = styled.div<ContainerProps>`
  width: 100%;

  height: fit-content;
  border-radius: 8px;
  position: relative;

  margin-top: 8px;
  padding-top: 10px;

  background-color: #ffffff;
  transition: 0.3s background-color ease-in-out;

  border: 1px solid ${colors.gray300};

  textarea {
    width: 100%;
    height: 190px;

    resize: none;

    position: relative;
    padding: 0 1rem;
    box-sizing: border-box;

    border: none;

    font-size: 14px;
    font-weight: 400;
    line-height: normal;

    background-color: transparent;
    color: #282828;
    outline: none;

    box-shadow: 0px 4px 10px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.2s padding ease-in-out;
    -webkit-appearance: none;
  }

  label {
    position: absolute;
    top: 24px;
    left: 16px;
    font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    color: #ffffff;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s all ease-in-out;
  }

  textarea::-webkit-input-placeholder {
    color: ${colors.gray400};
  }

  textarea::-moz-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }

  textarea:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }

  textarea:-moz-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }

  ${(props) =>
    !props.active &&
    css`
      &:hover {
        background-color: rgba(255, 255, 255, 0.45);
      }
    `}

  ${(props) =>
    props.active &&
    css`
      border: 1px solid ${colors.blue400};
      background-color: #ffffff;
      padding-top: 20px;

      textarea {
        padding: 10px 16px 8px 16px;
      }

      textarea::-webkit-input-placeholder {
        color: #ffffff;
      }

      label {
        top: 5px;
        opacity: 1;
        color: ${colors.gray600};
        z-index: 100;
      }
    `}
`;

export const InputContainer = styled.div<ContainerProps>`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  position: relative;

  background-color: #ffffff;
  transition: 0.3s background-color ease-in-out;
  margin-top: 10px;

  input {
    width: 100%;
    height: 48px;

    position: relative;
    padding: 0 1rem;
    box-sizing: border-box;

    border: 1px solid ${colors.gray300};
    border-radius: 8px;

    font-size: 14px;
    font-weight: 400;
    line-height: normal;

    background-color: transparent;
    color: #282828;
    outline: none;

    box-shadow: 0px 4px 10px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.2s padding ease-in-out;
    -webkit-appearance: none;
  }

  label {
    position: absolute;
    top: 24px;
    left: 16px;
    font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    color: #ffffff;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s all ease-in-out;
  }

  input::-webkit-input-placeholder {
    color: ${colors.gray400};
  }

  input::-moz-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }

  input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }

  input:-moz-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }

  ${(props) =>
    !props.active &&
    css`
      &:hover {
        background-color: rgba(255, 255, 255, 0.45);
      }
    `}

  ${(props) =>
    props.active &&
    css`
      background-color: #ffffff;

      input {
        border: 1px solid ${colors.blue400};
        padding: 24px 16px 8px 16px;
      }

      input::-webkit-input-placeholder {
        color: #ffffff;
      }

      label {
        top: 5.4px;
        opacity: 1;
        color: ${colors.gray600};
      }
    `}
`;
